import { LucideProps } from 'lucide-react'

const ArrowLeftIcon = (props: LucideProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.1429 12H0.857147"
      stroke="black"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.85715 6L0.857147 12L6.85715 18"
      stroke="black"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowLeftIcon
