import { create } from 'zustand'
import { getCookie, setUniversityCookie } from '@cerifi/utils/client-cookie'
import { UniversityEnum } from '@cerifi/utils/enums-and-constants/university-enum'

interface UniversityStore {
  universityEntryId: string | null
  universityName: string | null
  setUniversityEntryId: (entryId: string) => Promise<void>
  setUniversityName: (name: string) => void
}

export const useUniversityStore = create<UniversityStore>((set) => ({
  universityEntryId: null,
  universityName: null,
  setUniversityEntryId: async (entryId) => {
    const cookie = getCookie(UniversityEnum.UNIVERSITY_AFFILIATE)
    if (cookie) {
      set({
        universityEntryId: entryId,
      })
    }
    setUniversityCookie(entryId)
  },
  setUniversityName: (name: string) => {
    set({
      universityName: name,
    })
  },
}))
