'use client'

import { useEffect } from 'react'

const HideDrift = () => {
  useEffect(() => {
    const element = document.getElementById('drift-frame-controller')
    if (!element) return
    element.style.display = 'none'
    return () => {
      element.style.display = 'block'
    }
  }, [])

  return null
}

export default HideDrift
