'use client'

import { useEffect } from 'react'
import { useUniversityStore } from '@cerifi/state/university.store'

export const UniversityNameSetter = (props: {
  config: {
    universityName: string
    affiliateContentfulId: string
  }
}) => {
  const [setUniversityEntryId, setUniversityName] = useUniversityStore(
    (state) => [state.setUniversityEntryId, state.setUniversityName]
  )

  useEffect(() => {
    setUniversityEntryId(props.config.affiliateContentfulId)
    setUniversityName(props.config.universityName)
  }, [props.config, setUniversityEntryId, setUniversityName])

  return null
}
