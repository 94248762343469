'use client'

import { useRouter } from 'next/navigation'
import { Button } from '@cerifi/ui/components/button'
import ArrowLeft from '@cerifi/ui/components/icons/arrow-left'

export const EnrollmentBackBtn = () => {
  const router = useRouter()
  return (
    <Button variant="link" onClick={() => router.back()}>
      <div className=" flex items-center gap-3">
        <ArrowLeft />
        <div>Back</div>
      </div>
    </Button>
  )
}
